
import note1 from '../assets/imgs/note1.png';
import note2 from '../assets/imgs/note2.png';
import note3 from '../assets/imgs/note3.png';
import faq1 from '../assets/imgs/faq1.png';
import faq2 from '../assets/imgs/faq2.png';
import faq3 from '../assets/imgs/faq3.png';
import note1Mobile from '../assets/imgs/mobile/note1.png';
import note2Mobile from '../assets/imgs/mobile/note2.png';
import note3Mobile from '../assets/imgs/mobile/note3.png';
import faq1Mobile from '../assets/imgs/mobile/faq1.png';
import faq2Mobile from '../assets/imgs/mobile/faq2Mobile.png';
import faq3Mobile from '../assets/imgs/mobile/faq3.png';
export const page1 = [
  {
    img: note1,
    mobImg: note1Mobile,
    icon: '#iconzu5',
    title: 'Seamless step for application',
    description: 'Simple imformation with 1 legal ID',
  },
  {
    img: note2,
    mobImg: note2Mobile,
    icon: '#iconzu2',
    title: 'Fast audit check',
    description: 'A pleasant experience you never have',
  },
  {
    img: note3,
    mobImg: note3Mobile,
    icon: '#iconzu4',
    title: 'Rapid disbursement',
    description: 'Disbursed as soon as possible once you get approved',
  },
];

export const page3 = [
  {
    img: faq1,
    mobImg: faq1Mobile,
    title: 'What qualifications are required？',
    descriptions: [
      '1) A Phone number in use',
      '2) Valid government ID',
      '3) Stable income',
      '4) Age between 18-49 years old'
    ],
  },
  {
    img: faq2,
    mobImg: faq2Mobile,
    title: 'How to apply a loan? ',
    descriptions: [
      '1) Install OPeso and register with your own phone number.',
      '2) Fill out peso loan application form.',
      '3) Wait for cash loan approval.',
      '4) Claim your personal loan.'
    ],
  },
  {
    img: faq3,
    mobImg: faq3Mobile,
    title: 'How to repay your cash loan?',
    descriptions: [
      '1)M Lhuillier:Visit M Lhuillier and fill the repay form.',
      '2)7-Eleven: Go to 7-Eleven and repay via CLiQQ machine or CLiQQ app. ',
      '3)GCash,Coins: Open app, click Pay Bills to access Loans option to repay.',
      '4)Bank: Deposit money to our corporate bank account given in the app.',
      '5)ECPAY: Go to ECPAY, fill out needed information in Bills Payment Form, reimburse your personal loans and receive a payment confirmation receipt.'
    ]
  },
];
