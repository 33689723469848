import React from "react";
import { HashRouter as Router, Link } from "react-router-dom";
import email from "../assets/imgs/email.png";
import emailMobile from "../assets/imgs/mobile/email.png";
import testSec from "../assets/imgs/testSec.png";

function Footer(props) {
  if (props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile">
        <div className="one-center">
          <div className="title">Regulations</div>
          <Router>
            <div className="infosSelf">
              <Link to="/OPesoLoanAgreement" target="_blank">
                《OPeso Loan Agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/privacyAgreement" target="_blank">
                《Privacy Agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/serviceAgreement" target="_blank">
                《Service Agreement》
              </Link>
            </div>
          </Router>
        </div>
        <div className="one-center-end">
          {/* <div className='title'>Codeblock Lending Inc.</div> */}
          <div className="infos">
            <img src={emailMobile} className="contImg" alt="fottOne" />
            <div>service@opeso.ph</div>
          </div>
          <div className="infos">
            <img src={testSec} className="contImg" alt="fottOne" />
            <div>Company Registration: NO.CS201913681</div>
          </div>
          <div className="infos">
            <img src={testSec} className="contImg" alt="fottOne" />
            <div>Certificate Of Authority: NO.3059</div>
          </div>
        </div>
      </footer>
    );
  }
  return (
    <footer id="footer" className={"footer"}>
      <div className="bar">
        <div className="one-center">
          <div className="title">Codeblock Lending Inc.</div>
          <div className="infos">
            <img src={email} className="contImg" alt="fottOne" />
            <div>service@opeso.ph</div>
          </div>
          <div className="infos">
            <img src={testSec} className="contImg" alt="fottOne" />
            <div>Company Registration: NO.CS201913681</div>
          </div>
          <div className="infos">
            <img src={testSec} className="contImg" alt="fottOne" />
            <div>Certificate Of Authority: NO.3059</div>
          </div>
        </div>
        <div className="one-center one-center-end">
          <div className="title">Regulations</div>
          <Router>
            <div className="infos">
              <Link to="/OPesoLoanAgreement" target="_blank">
                《OPeso Loan Agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/privacyAgreement" target="_blank">
                《Privacy agreement》
              </Link>
            </div>
            <div className="infos">
              <Link to="/serviceAgreement" target="_blank">
                《service agreement》
              </Link>
            </div>
          </Router>
        </div>
      </div>
      <div className="bottom-bar">
        Copyright © 2020 | OPeso | All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;
